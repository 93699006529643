import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const Menu = () => {
	return (
		<aside className="menu column is-2">
			<p className="menu-label">Área del Cliente</p>
			<ul className="menu-list  is-size-7-mobile is-size-6">
				<li>
					<NavLink to="/cliente" exact activeClassName="is-active" className="navbar-item">
						Tablero
					</NavLink>
				</li>
				<li>
					<NavLink to="/cliente/pagos" exact activeClassName="is-active" className="navbar-item">
						Planes
					</NavLink>
				</li>
				<li>
					<NavLink to="/cliente/payment-methods" exact activeClassName="is-active" className="navbar-item">
						Metodos de Pagos
					</NavLink>
				</li>
				<li>
					<NavLink to="/cliente/profile" exact activeClassName="is-active" className="navbar-item">
						Perfil
					</NavLink>
				</li>
			</ul>
		</aside>
	);
};

export default Menu;
