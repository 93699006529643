import React, { useEffect, useState } from "react";
import axios from "axios";
require("dotenv");
const InformacionFactuacion = () => {

    const [Respuesta, setRespuesta] = useState(false)
    const [Informacion, setInformacion] = useState({})

    useEffect(() => {
        if(!Respuesta){
            getInformacion();
        }
    });

    const getInformacion = (e) => {
        const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/informacion-facturacion' : process.env.REACT_APP_API_DEVELOP + '/informacion-facturacion';

        const res = axios.post(path, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
            }
        })
        res.then(
            (response) => {
                setRespuesta(true);
                setInformacion(response.data);
            },
            (error) => {
                setRespuesta(false);
            }
        );

    }

    return (
    <React.Fragment>
     <div className="card mt-5">
        <header className="card-header">
          <p className="card-header-title">
           Información de la Facturación
          </p>
          <a className="card-header-icon" aria-label="more options">
            <span className="icon">
              <i className="fal fa-receipt" aria-hidden="true"></i>
            </span>
          </a>
        </header>
        <div className="card-content">
          <div className="content">
            {Informacion &&  <div className="columns">
                <div className="column is-4">
                    <h1 className=" is-size-7-mobile is-size-7">Factura Dirección</h1>
                    <p className="is-size-7-mobile is-size-7">{Informacion.emp_dir}</p>
                </div>
                <div className="column is-4">
                    <h1 className="is-size-7-mobile is-size-7">Cantacto de Facturación</h1>
                    <p className="is-size-7-mobile is-size-7">{Informacion.emp_ema}</p>
                </div>
            </div>}
          </div>
        </div>
      </div>
    </React.Fragment>
    )

}

export default InformacionFactuacion;