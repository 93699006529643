import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useUser from '../hooks/useUsers';
import logo from '../images/logo4.png';

require("dotenv");

function Header(props) {
    const { isLogged, login, logout } = useUser();
    const [isActive, setisActive] = useState(false);

    const nubefaTitle = {
        fontSize: "20px",
        color: '#fff',
        right: '5px'
    }

    const logotipo = {
        top: '2px',
        left: '10px'
    }



    return (
        <div className="">
			<div className="columns">
				<div className="column">
					<nav className="navbar is-primary has-shadow is-fixed-top" role="navigation" aria-label="main navigation">
						<div className="navbar-brand">
							<figure className="image figur" style={logotipo}>
							      <img className="logo" alt="logo" src={logo} />
							</figure>

						    {/* <span className="navbar-item mr-0 pr-0 mb-1 " style={nubefaTitle}>
							    <h1 className="nunito is-size-4">
									NUBEFA
							    </h1>
						    </span> */}

							<a onClick={() => {
					              setisActive(!isActive);
					            }}
								role="button"
								className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
								aria-label="menu"
								aria-expanded="false"
								data-target="navbar"
							>
								<span aria-hidden="true" />
								<span aria-hidden="true" />
								<span aria-hidden="true" />
							</a>
							<div class="navbar-end invisible" style={{margin:'auto'}} >
									<a href="tel:51993400767" className="has-text-white">
										<div className="icon-text">
										<span className="icon has-text-white">
											<i className="fas fa-mobile-android-alt"></i>
										</span>
										<span>993400767</span>
										</div>
									</a>
								</div>

						</div>
					

						<div id="navbar" className={`navbar-menu ${isActive ? "is-active" : ""}`} >
							{!isActive ? <div  class="navbar-end" style={{margin:'auto',paddingLeft:"250px"}}>
							<a href="tel:51993400767" className="has-text-white">
								<div className="icon-text">
								  <span className="icon has-text-white">
								    <i className="fas fa-mobile-android-alt"></i>
								  </span>
								  <span>993400767</span>
								</div>
							</a>
								</div>:null}

							<div className="navbar-end">
							
								<NavLink to="/" exact activeClassName="is-active has-text-weight-bold" className="navbar-item has-text-white has-text-weight-light is-item-hoverable has-text-centered">
									Inicio
								</NavLink>

								<NavLink to="/comprobante" activeClassName="is-active has-text-weight-bold" className="navbar-item has-text-white has-text-weight-light is-item-hoverable  has-text-centered">
									Consultar <br/> Comprobantes
								</NavLink>

								<NavLink to="/cliente" activeClassName="is-active has-text-weight-bold" className="navbar-item has-text-white has-text-weight-light is-item-hoverable  has-text-centered">
									Área de <br/>Cliente
								</NavLink>

								{isLogged ? (
									<React.Fragment>
											<div className="navbar-item has-dropdown is-hoverable">
											<a className="navbar-link"><i className="fas fa-user-circle" /> </a>
											<div className="navbar-dropdown">
												<NavLink
													className="navbar-item"
													activeClassName="is-active"
													to="/cliente/profile"
												>
												Perfil
												</NavLink>
												<hr className="navbar-divider" />
												<a className="navbar-item" onClick={logout}>
													Cerrar Sesión
												</a>
											</div>
										</div>
										<div className="navbar-item">
											<a href={process.env.REACT_APP_URL } className="button is-link-reverse">Ingresa</a>
										</div>
									</React.Fragment>
								) : (
									<React.Fragment>
										<div className="navbar-item center">
											<NavLink
												className="button is-link"
												activeClassName="is-active"
												to="/registro"
											>
												Regístrate
										</NavLink>
										</div>
										<div className="navbar-item center">
											<a href={process.env.REACT_APP_URL } className="button is-link-reverse">Ingresa</a>

										</div>
									
									</React.Fragment>
								)}
								<div class="redes is-flex" style={{justifyContent:"center"}}>
								<div className="navbar-item">
									<a href={process.env.REACT_APP_FACEBOOK } className="has-text-weight-light has-text-white"><i class="fab fa-facebook-f"></i></a>
								</div>
								<div className="navbar-item">
									<a href={process.env.REACT_APP_YOUTUBE } className="has-text-weight-light has-text-white"><i class="fab fa-youtube"></i></a>
								</div>
								<div className="navbar-item">
									<a href={process.env.REACT_APP_INSTAGRAM } className="has-text-weight-light has-text-white"><i class="fab fa-instagram"></i></a>
								</div>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</div>
    );
}

export default Header;