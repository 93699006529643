import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import LoginForm from '../components/elements/LoginForm';
import Forget from '../components/elements/Forget';
import useUser from '../hooks/useUsers';
//import { useLocation } from 'react-router-dom';
import Notificacion from '../components/elements/Notificacion';
import login_nubefa from '../images/LOGIN_NUBEFA.svg';

const Clientes = () => {
	const [Loading, setLoading] = useState(0);
	const [showForget , setShowForget] = useState(false);
	const [Response, setResponse] = useState({ created: true, errors: null, success: null, text: '' });
	const [User, setUser] = useState({});
	const { login, isLogged, hasLoginError } = useUser();
	const { Bienvenido } = useParams();
	useEffect(
		() => {
			try {
				Bienvenido && setWelcome();
			} catch (error) {
				console.log('no data avalible');
			}
		},
		[0]
	);


	const handleLogin = (e) => {
		e.preventDefault();
		setLoading(1);

		setTimeout(() => {
			login(User);
			setLoading(0);
		}, 1000);
	};

	const setWelcome = () => {
		setResponse({
			created: true,
			errors: [
				'Tu cuenta fue creada con Exito!',
				'Te hemos enviado un correo de confirmación',
				'Tienes 30 dias Gratis a partir de ahora',
				'Inicia sesión para continuar'
			],
			success: null,
			text: null
		});
	};

	return (
		<React.Fragment>
			<Header />
			{isLogged ? (
				<div>Logged</div>
			) : (
				<section className="container mt-5 pt-5">
					<div className="columns mt-5">
						<div className="column is-8 is-offset-2">
							<div className="columns mr-3 ml-3">
								<div className="column mr-3 ml-3 mt-3 is-hidden-touch">
									<h1 className="is-size-2 has-text-weight-bold has-text-black">Área de Cliente</h1>
									<img src={login_nubefa} alt="Inicia sesion en Nubefa"/>
									{Response.errors != null && (
										<Notificacion
											is="is-light is-primary"
											Response={Response}
											CloseNotification={setResponse}
										/>
									)}
								</div>

								<div className="column has-text-centered">
									<div className="card">
										<div className="card-content">
											
											{!showForget && (
											<LoginForm
												handle={handleLogin}
												target={User}
												state={setUser}
												load={Loading}
												error={hasLoginError}
												setShowForget = {setShowForget}
												/>) }

											{showForget && (
												<Forget
													setShowForget= {setShowForget}
											/>)
										}
										</div>
									</div>
								</div>
								<div className="column mr-3 ml-3 mt-3 is-hidden-desktop">

									<h1 className="is-size-2 has-text-weight-bold has-text-black">Área de Cliente</h1>

									<img src={login_nubefa} alt="Inicia sesion en Nubefa"/>
									{Response.errors != null && (
										<Notificacion
											is="is-light is-primary"
											Response={Response}
											CloseNotification={setResponse}
										/>
									)}
								</div>
					
							</div>
						</div>
					</div>
				</section>
			)}
		</React.Fragment>
	);
};
export default Clientes;
