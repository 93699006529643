import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import Dashboard from './components/panel/Dashboard';
import PanelLayoutRoute from './components/panel/Panel';
import PaymentMethods from './components/panel/PaymentMethods';
import Profile from './components/panel/Profile';
import Pricings from './components/Pricings';
import { UserContextProvider } from './context/UserContext';
import Comprobante from './pages/Comprobante';
import Inicio from './pages/Inicio';
import Payment from './pages/Payment';
import Register from './pages/Register';
import Terminos from './pages/Terminos';
import './style/style.scss';

function App() {
	return (
		<UserContextProvider>
			<Router>
				<Switch>
					<PanelLayoutRoute exact path="/cliente" component={Dashboard} />
					<PanelLayoutRoute exact path="/cliente/welcome/:Bienvenido" component={Dashboard} />
					<PanelLayoutRoute exact path="/cliente/dashboard" component={Dashboard} />
					<PanelLayoutRoute exact path="/cliente/pagos" component={Pricings} />
					<PanelLayoutRoute exact path="/cliente/payment/:plan/:action" component={Payment} />
					<PanelLayoutRoute exact path="/cliente/payment-methods" component={PaymentMethods} />
					<PanelLayoutRoute exact path="/cliente/profile" component={Profile} />

					<Route path="/" exact component={Inicio} />
					<Route path="/registro" exact component={Register} />
					<Route path="/terminos-y-condiciones" exact component={Terminos} />

					<Route path="/comprobante" exact component={Comprobante} />
					<Route path="/comprobante/:RUC/:Documento/:Serie/:Correlativo" exact component={Comprobante} />
				</Switch>
				<Footer />
			</Router>
		   
		</UserContextProvider>
	);
}

export default App;
