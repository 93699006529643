import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Notificacion from '../elements/Notificacion';
import NfButton from '../elements/ButtonLoading';

import { ToastContainer, toast } from 'react-toastify';

const Profile = () => {
    const [ Buscar, setBuscar ] = useState(0);
    const [Response, setResponse] = useState({ created: null, errors: null, success: null });
    const [user, setUser] = useState({});

    useEffect(
        () => {
            getProfileData();
        },
        [setUser]
    );

    const handleUser = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const getProfileData = () => {
            const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/profile' : process.env.REACT_APP_API_DEVELOP + '/profile';
            const res = axios.post(path, {}, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
                }
            })
            res.then(
                (response) => {
                    setUser(response.data);
                },
                (error) => {
                toast.error("Error al Obtener el perfil del Usuario vuelva a ingresar al Sistema",
                    {position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                    setUser('Bienvenido a Nubefa');
                }
            ); 
    }

    const updateUser = (e) => {
        setBuscar(1);
        const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/profile' : process.env.REACT_APP_API_DEVELOP + '/profile';
        const res = axios.put(path, user, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
            }
        })
        res.then(
            (response) => {
                setBuscar(0);
                setUser(response.data);
                setResponse(response.data)
                toast.success("Tus datos fueron actualizados correctamente",
                    {position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                    })
                getProfileData();

            },
            (error) => {
                setBuscar(0);
                toast.error("Verifique que el la nueva contraseña y la contraseña de confirmación sean iguales",
                    {position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                    })
            }
        );
    }

    // const refreshUser = (e) => {
    //     e.preventDefault();
    // }

    // const notify = () => toast("Wow so easy!");

    return (
        <React.Fragment>
        <div className="ml-3 mr-3 mt-5">
            <div className="card mt-5">
                <header className="card-header">
                  <p className="card-header-title">
                   Perfil
                  </p>
                  <a className="card-header-icon" aria-label="more options">
                    <span className="icon">
                      <i className="fal fa-user-circle" aria-hidden="true"></i>
                    </span>
                  </a>
                </header>
                <div className="card-content">
                  <div className="content is-size-7-mobile is-size-7">
                    
                    <div className="columns is-mobile">
                            <div className="column is-one-third is-one-third-mobile">
                                <figure className="image is-128x128 is-hidden-touch">
                                    <img className="is-rounded" src={user.photo_profile} />
                                </figure>
                                 <figure className="image is-48x48 is-hidden-desktop">
                                    <img className="is-rounded" src={user.photo_profile} />
                                </figure>
                            </div>

                            <div className="column is-half-mobile content is-vcentered">
                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">Identificacion del Usuario</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded">
                                                <input className="input" type="text" placeholder="User Key" readOnly value={user.id} />
                                            </p>
                                        </div>
                                        <div className="field">
                                            <button className="button is-primary" onClick={() => navigator.clipboard.writeText(user.id)} >Copiar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                </div>
              </div>

              <div className="card mt-5">
                <header className="card-header">
                  <p className="card-header-title">
                   Editar Mis Datos
                  </p>
                  <a className="card-header-icon" aria-label="more options">
                    <span className="icon">
                      <i className="fal fa-pencil" aria-hidden="true"></i>
                    </span>
                  </a>
                </header>
                <div className="card-content">
                  <div className="content is-size-7-mobile is-size-7">
                    <div className="columns is-mobile">
                        <div className="column is-half-desktop">
                        <div className="field">
                                    <label className="label">Nombre</label>
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" type="text" placeholder="Nombre" name="name" value={user.name} onChange={handleUser} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </p>
                                </div>
                                <div className="field">
                                    <label className="label">Apellido</label>
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" type="text" placeholder="Apellido" name="lastname" value={user.lastname} onChange={handleUser} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </p>
                                </div>
                                <div className="field">
                                    <label className="label">Email</label>
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" type="email" placeholder="Email" name="email" value={user.email} onChange={handleUser} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </p>
                                </div>
                                <div
                                    className="field">
                                    <label className="label">Nueva Contraseña</label>
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" type="password" name="new_password" value={user.new_password} onChange={handleUser} placeholder="Contraseña" autoComplete={false} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-lock"></i>
                                        </span>
                                    </p>
                                </div>
                                <div className="field">
                                    <label className="label">Confirme Contraseña</label>
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" type="password" name="password_confirmation" value={user.password_confirmation} onChange={handleUser} placeholder="Confirme Contraseña" autoComplete={false} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-lock"></i>
                                        </span>
                                    </p>
                                </div>
                                {Response.errors && <Notificacion
                                    is="is-link is-danger"
                                    CloseNotification={setResponse}
                                    Response={Response}
                                />}

                                <div className="field is-grouped">
                                    <p className="control">
                                    <NfButton
                                        label="Actualizar"
                                        is="is-primary is-fullwidth"
                                        state={Buscar}
                                        handleClick={updateUser}
                                    />
                                       
                                    </p>
                                </div>
                                <ToastContainer />
                            </div>
                        </div>
                  </div>
                </div>
              </div>
        </div>
    </React.Fragment>
    )


}

export default Profile;