import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import PagoNiubiz from '../components/elements/PagoNiubiz';
import Notificacion from '../components/elements/Notificacion';
import FormPayNiubiz from '../components/elements/FormPayNiubiz';
const Payment = () => {
  const [PayResponse, setPayResponse] = useState({ status: false });
  const [ResponseInvoice, setResponseInvoice] = useState(false)
  const [PayInvoice, setPayInvoice] = useState({});
  const [Information, setInformation] = useState({});
  const [Response, setResponse] = useState({ created: true, errors: null, success: null, text: '' });

  const { plan, action } = useParams();

  const handleFormulario = (e) => {
    setInformation({
      ...Information,
      [e.target.name]: e.target.value
    });
  };

  const guardarDeposito = (e) => {
    e.preventDefault();
    const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/pagar-transferencia/' : process.env.REACT_APP_API_DEVELOP + '/pagar-transferencia/';
    const res = axios.post(path, { plan, Information, action }, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
      }
    })
    res.then(
      (response) => {
        setResponse(response.data);
      },
      (error) => {
        alert('error en el servidor intente mas tarde')
      }
    );
  }
  const getInvoice = () => {
    const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/get-invoice' : process.env.REACT_APP_API_DEVELOP + '/get-invoice';
    const res = axios.post(path, { plan }, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
      }
    })
    res.then(
      (response) => {
        setPayInvoice(response.data);
        setResponseInvoice(true)
      },
      (error) => {
        setResponseInvoice(false)
        alert('error en el servidor intente mas tarde')
      }
    );
  }

  let amount = 0;

  switch (plan) {
    case 'basic':
      amount = 39.00
      break;

    case 'standar':
      amount = 69.00
      break;

    case 'premium':
      amount = 99.00
      break;

    default:
      amount = null;
      if (!ResponseInvoice) {
        getInvoice(plan);
      }

      break;
  }

  return (
    <React.Fragment>
      <section className="container  register-user">
        <div className="columns is-multiline">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column mr-3 ml-3">
                <div className="card">
                  {!PayResponse.status ?
                    (<div className="card-content">
                      <span className="is-pulled-left title">Paga tu Orden</span>
                      <span className="is-pulled-right title">Online</span>
                      <br /><br /><br />
                      <PagoNiubiz setPayResponse={setPayResponse} />
                    </div>) :
                    (<FormPayNiubiz PayResponse={PayResponse}></FormPayNiubiz>)}
                  <footer className="card-footer">
                    <p className="card-footer-item">
                      <span>
                        <h1 className="is-size-5 has-text-weight-light">Total</h1>
                      </span>
                    </p>
                    <p className="card-footer-item">
                      <span>
                        <h1 className="is-size-5 has-text-weight-bold">
                          <small className="is-size-7">S/</small> {
                            (amount === null && <span>{PayInvoice.precio}</span>) ||
                            (amount != null && <span>{amount}</span>)
                          }
                        </h1>
                      </span>
                    </p>
                  </footer>
                </div>
                <div className="card mt-5">
                  <div className="card-content">
                    <span className="is-pulled-left title">Paga tu Orden</span>
                    <span className="is-pulled-right title">Depósito</span>
                    <br /><br /><br />
                    <div className="field is-horizontal">

                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <div className="select is-fullwidth">
                              <select
                                onChange={handleFormulario}
                                name="Bank"
                                value={Information.Bank}
                              >
                                <option value="SELECT">BANCO</option>
                                <option value="BCP">BCP</option>
                                <option value="SCOTIABANK">SCOTIABANK</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="field">
                          <p className="control is-expanded has-icons-left has-icons-right">
                            <input
                              onChange={handleFormulario}
                              name="Codigo"
                              value={Information.Codigo}
                              className="input is-success" type="text" placeholder="CODIGO DE LA OPERACION" />
                            <span className="icon is-small is-left">
                              <i className="fas fa-university"></i>
                            </span>
                          </p>
                        </div>
                        <div className="field">
                          <p className="control is-expanded">
                            <button onClick={guardarDeposito} className="button is-primary">Pagar</button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Response.errors != null && (
                    <Notificacion
                      is="is-light is-primary"
                      Response={Response}
                      CloseNotification={setResponse}
                    />
                  )}
                  <footer className="card-footer">
                    <p className="card-footer-item">
                      <span>
                        <h1 className="is-size-5 has-text-weight-light">Total</h1>
                      </span>
                    </p>
                    <p className="card-footer-item">
                      <span>
                        <h1 className="is-size-5 has-text-weight-bold">
                          <small className="is-size-7">S/</small>  {
                            (amount === null && <span>{PayInvoice.precio}</span>) ||
                            (amount != null && <span>{amount}</span>)
                          }
                        </h1>
                      </span>
                    </p>
                  </footer>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Payment;
