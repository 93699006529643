import React, { useState} from 'react';
import useUser from '../../hooks/useUsers';

import Notificacion from '../../components/elements/Notificacion';
import { GoogleLogin } from 'react-google-login';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

require('dotenv');

const RegistroConPlataformas = (props) => {


    const [warning, setWarning] = useState({
        created: true,
        errors: [
            'Tu cuenta de Nubefa no se esta asociada a esta forma de inicio de sesión',
        ],
        success: false,
        text: null
    });
    const { googleOAuth, facebookOAuth, hasLoginError, setState } = useUser();

    const responseGoogle = (response) => {
      googleOAuth(response)
    }
    const responseBadGoogle = (response) => {
        CloseNotification();
    }
    const responseFacebook = (response) => {
        facebookOAuth(response)
    }
    const componentClicked = (e) => {
        CloseNotification();
    }

    const CloseNotification = () => {
        setState({ Loading: false, Error: false });
    }


    return (
        <React.Fragment>
		<GoogleLogin
				clientId={process.env.REACT_APP_GOOGLE_TOKEN}
				render={renderProps => (
					<button onClick={renderProps.onClick} className="button is-primary is-outlined is-rounded is-fullwidth">
						<span className="icon">
							<i className="fab fa-google" />
						</span>
						<span>Usa tu cuenta de Google</span>
					</button>
				)}
				autoLoad={false}
				buttonText="Login"
				onSuccess={responseGoogle}
				onFailure={responseBadGoogle}
				cookiePolicy={'single_host_origin'}
		/>
			<div className="mt-2" />

		<FacebookLogin
	  	appId="1021939218560353"
	  	autoLoad={false}
	  	fields="name,email,picture"
	  	callback={responseFacebook}
	    render={renderProps => (
	   	<button onClick={renderProps.onClick} className="button is-primary is-outlined is-rounded is-fullwidth">
				<span className="icon">
					<i className="fab fa-facebook" />
				</span>
				<span>Usa tu cuenta de Facebook</span>
		</button>
		  )}
		/>
			<div className="mt-2" />
			{hasLoginError && (<Notificacion is="is-light is-danger" Response={warning} CloseNotification={componentClicked} />)}

		</React.Fragment>
    );
};
export default RegistroConPlataformas;