import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import NfButton from '../components/elements/ButtonLoading';
import Header from '../components/Header';
import axios from 'axios';
import Pricings from '../components/Pricings';


require('dotenv');

const Comprobante = () => {
    const { RUC, Documento, Serie, Correlativo } = useParams();
    const [Comprobante, setComprobante] = useState({});
    const [Buscar, setBuscar] = useState(0);
    const [Respuesta, setRespuesta] = useState(0);
    const [Factura, setFactura] = useState({});

    useEffect(
        () => {
            try {
                setComprobante({
                    RUC,
                    Documento,
                    Serie,
                    Correlativo
                });
            } catch (error) {
                console.log('no data avalible');
            }
        },
        [0]
    );

    const handleFormulario = (e) => {
        setComprobante({
            ...Comprobante,
            [e.target.name]: e.target.value
        });
    };

    const BuscarComprobante = (e) => {
        e.preventDefault();
        setBuscar(1);
        setRespuesta(0);
        const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/buscar-comprobantes?datos=' + JSON.stringify(Comprobante) : process.env.REACT_APP_API_DEVELOP + '/buscar-comprobantes?datos=' + JSON.stringify(Comprobante);

        const res = axios.get(path);
        res.then(
            (response) => {
            	console.log(response)
                setBuscar(0);
                setRespuesta(2);
                setFactura(response.data.success);
            },
            (error) => {
                setBuscar(0);
                setRespuesta(1);
            }
        );
    };

    const DescargarComprobante = (e) => {
        e.preventDefault();

        const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/descargar-comprobante?datos=' + JSON.stringify(Factura) : process.env.REACT_APP_API_DEVELOP + '/descargar-comprobante?datos=' + JSON.stringify(Factura);
        const res = axios.get(path, { responseType: 'blob' });

        res.then(
            (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', Factura.xml_sunat + '.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                console.log(error);
            }
        );
    };

    return (
        <div>
			<Helmet>
				<title>NUBEFA | Consulta de Comprobante</title>
			</Helmet>
			<Header />
			<div className="container mt-5">
				<div className="columns mt-5">
					<div className="column is-half is-offset-one-quarter">
						<section className="mt-5 pt-5">
								<div className="card">
								  <div className="card-content">
										<h4 className="is-size-4 is-size-5-mobile has-text-weight-bold has-text-centered has-text-black ">Consulta De Comprobante</h4>
										<form className="form">

											<div className="field">
												<div className="control">
													<input
														onChange={handleFormulario}
														name="RUC"
														className="input"
														type="number"
														placeholder="Ruc de la Empresa Emisora"
														value={Comprobante.RUC}
													/>
												</div>
												<p className="help is-success">Numero de RUC de la empresa que emitio tu comprobante </p>
											</div>

											<div className="field has-addons">
												<div className="control">
													<span className="select">
														<select
															onChange={handleFormulario}
															value={Comprobante.Documento}
															name="Documento"
														>
															<option value="null">--</option>
															<option value="FA">FA</option>
															<option value="BO">BO</option>
														</select>
													</span>
												</div>
												<div className="control">
													<input
														onChange={handleFormulario}
														name="Serie"
														className="input"
														type="text"
														placeholder="Serie"
														value={Comprobante.Serie}
													/>
												</div>
												<div className="control">
													<input
														onChange={handleFormulario}
														name="Correlativo"
														className="input"
														type="number"
														placeholder="Correlativo"
														value={Comprobante.Correlativo}
													/>
												</div>
											</div>

											<div className="field has-text-centered">
												<div className="control">
													<NfButton
														label="Buscar Comprobante"
														is="is-primary"
														state={Buscar}
														handleClick={BuscarComprobante}
													/>
												</div>
											</div>
										</form>
								  </div>
								</div>
						</section>
					</div>
				</div>
				{ Respuesta === 0 ? (<div/>):(
				<div className="columns">
					<div className="column is-half is-offset-one-quarter">
					{Respuesta === 1 ? (
							<div className="card">
								<div className="card-content is-full">
									<button className="button is-fullwidth is-danger">
										<span>Comprobante no encontrado</span>
										<span className="icon">
											<i className="fal fa-exclamation-triangle" />
										</span>
									</button>
								</div>
							</div>
						):Respuesta === 2 ? (
							<div className="card">
								<div className="card-content is-full">
									<button className="button is-fullwidth is-light" onClick={DescargarComprobante}>
										<span>
											Fecha {Factura.updated_at} Archivo: {Factura.xml_sunat}.zip
										</span>
										<span className="icon">
											<i className="fal fa-download" />
										</span>
									</button>
								</div>
							</div>
						): <div/>
					}
					</div>
				</div>
				)
			}
				<Pricings />
			</div>
		</div>
    );
};

export default Comprobante;