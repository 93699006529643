import React from 'react';
import { Redirect } from 'react-router-dom';
import RegisterForm from '../components/elements/RegisterForm';
import Header from '../components/Header';
import useUser from '../hooks/useUsers';

const Register = () => {
	let { isLogged } = useUser();
	return (
		<React.Fragment>
		{isLogged ? <Redirect to="/cliente" /> : (
			<>
				<Header />
				<RegisterForm  className="mt-5 pt-5"/>
			</>
		)}
		</React.Fragment>
	);
};

export default Register;
