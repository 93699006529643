require('dotenv');
export default function login(User) {
	console.log(process.env,'from login');
	const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/login' : process.env.REACT_APP_API_DEVELOP + '/login';
	User = {
		email: User.username,
		password: User.password
	};

	return fetch(path, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(User)
	})
		.then((res) => {
			if (!res.ok) throw new Error('Response is not ok');
			return res.json();
		})
		.then((res) => {
			const jwt = res.access_token;
			return jwt;
		});
}
