import React from 'react';
import CardModulo from '../components/elements/CardModulos.jsx';
import girl_shop_nubefa from '../images/NUBEFA_GIRL_2.svg';
import nubefa_nube_morada_1 from '../images/NUBE_MORADA.png';

////////////modulos
//compras  --shopping-cart
//Proveedores --- truck-loading
//ventas ----- receipt
//clientes ----  user-friends
//facturaccion electronica --- server
//Punto de venta -----

//cobros  --- hands-usd money-bill-wave
//pagos ----- hand-holding-usd

//productos y servicio ------ -alt

//stock -----------  boxes
//lista de precios --------- inventory
//libros contables  ---------- chart-bar

const Modulos = () => {
    return (
        <section className="pt-1 mt-5 mb-5 ">
		<div className="container">
		<img className="nube_black_5" src={nubefa_nube_morada_1} alt="NUBEFA_NUBE_5"/>
		<img className="nube_black_6" src={nubefa_nube_morada_1} alt="NUBEFA_NUBE_6"/>
			{/* <h1 className="is-size-1 is-size-patch-1 is-size-5-mobile has-text-weight-bold has-text-centered pl-3 pr-3">Todo lo que necesitas</h1> */}
			<div className="columns is-mobile">
				<div className="column is-offset-2-desktop is-6-desktop is-full-touch">
					<div className="columns is-mobile is-multiline pr-3 pl-3 nube_backgroud">
						<div className="column is-half-mobile is-half-desktop">
							<CardModulo title="Compras" icon="fa-shopping-cart" description="Con importador de XML" />
						</div>
						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Ventas" icon="fa-hand-holding-usd" description="Convierte tus Comprobantes" />
							{/*Reconvierte todos tus comprobantes e imprimelos A4 y Ticket*/}
						</div>

						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Sujetos" icon="fa-user-friends" description="Con buscador de RUC y DNI" />
						</div>
				
						<div className="column is-half-mobile is-half-desktop">
							<CardModulo title="Factura Electrónicas" icon="fa-file-code" description="Comprobantes ilimitados" />
						</div>
						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Punto de Venta" icon="fa-hand-holding-usd" description="Venta rápida con lector" />
						</div>

						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Caja" icon="fa-cash-register" description="Apertura caja por turnos" />
						</div>
						<div className="column is-half-mobile is-half-desktop">
							<CardModulo title="Bancos" icon="fa-university" description="Control de cobros y pagos" />
						</div>
						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Inventarios" icon="fa-boxes" description="Control de stock de productos" />
						</div>

						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Promociones" icon="fa-box-full" description="Crea kit de promociones" />
						</div>
						<div className="column is-half-mobile is-half-desktop">
							<CardModulo title="Margen de Utilidad" icon="fa-box-usd" description="Controla tus ganancias" />
						</div>
						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Reportes" icon="fa-file-chart-line" description="Saca tus reportes en Excel" />
						</div>

						<div className="column is-half-mobile is-half-desktop">
							 <CardModulo title="Libros Contables" icon="fa-chart-bar" description="Computarizados y Electrónicos" />
						</div>
					</div>
				</div>	

				<div className="column is-hidden-mobile">
					<img src={girl_shop_nubefa} alt="girl_modules_nubefa" className="" width="70%" />		
				</div>

			</div>
		</div>
	</section>

    );
};

export default Modulos;