import React from 'react';

const CardModulos = ({title,icon,description})=>{


return(

	<div className="block has-text-justifed">

    <div className="icon-text">
      <span className="icon has-text-primary ">
        <i className={'fal '+icon}></i>
      </span>
      <span className="is-size-7-mobile is-6"><strong className="has-text-primary">{title}</strong></span>
    </div>

    <p className="is-size-7-mobile">
      {description}
    </p>

  </div>


	)


}

export default CardModulos;