import { useContext, useCallback, useState } from 'react';
import Context from '../context/UserContext';
import loginService from '../services/login';
import googleOAuthService from '../services/googleOAuth';
import facebookOAuthService from '../services/facebookOAuth'
import RegisterService from '../services/register';

export default function useUser() {
	const { jwt, setjwt } = useContext(Context);
	const [Response, setResponse] = useState({ created: null, errors: null, success: null });
	
	const [state, setState] = useState({ Loading: false, Error: false });

	const register = useCallback(
		(User) => {
			setState({ Loading: true, Error: false });
			RegisterService(User)
				.then((res) => {
					setState({ Loading: false, Error: false });
					setResponse(res);
				})
				.catch((err) => {
					setState({ Loading: false, Error: true });
					setResponse(err);
				});
		},
		[setState, setResponse]
	);

	const login = useCallback(
		(User) => {
			setState({ Loading: true, Error: false });
			loginService(User)
				.then((jwt) => {
					sessionStorage.setItem('jwt', jwt);
					setState({ Loading: false, Error: false });
					setjwt(true);
				})
				.catch((err) => {
					sessionStorage.removeItem('jwt');
					setState({ Loading: false, Error: true });
					setjwt(false);
				});
		},
		[setjwt]
	);

	const googleOAuth = useCallback(
		(User) => {
			setState({ Loading: true, Error: false });
			googleOAuthService(User)
				.then((jwt) => {
					sessionStorage.setItem('jwt', jwt);
					setState({ Loading: false, Error: false });
					setjwt(true);
				})
				.catch((err) => {
					sessionStorage.removeItem('jwt');
					setState({ Loading: false, Error: true });
					setjwt(false);
				});
		},
		[setjwt]
	);

	const facebookOAuth = useCallback(
		(User) => {
			setState({ Loading: true, Error: false });
			facebookOAuthService(User)
				.then((jwt) => {
					sessionStorage.setItem('jwt', jwt);
					setState({ Loading: false, Error: false });
					setjwt(true);
				})
				.catch((err) => {
					sessionStorage.removeItem('jwt');
					setState({ Loading: false, Error: true });
					setjwt(false);
				});
		},
		[setjwt]
	);



	const logout = useCallback(
		() => {
			sessionStorage.removeItem('jwt');
			setjwt(false);
		},
		[setjwt]
	);

	return {
		isLogged: Boolean(jwt),
		isLoginLoading: state.Loading,
		hasLoginError: state.Error,
		hasResponse: Response,
		hasResponseState: setResponse,
		login,
		logout,
		register,
		googleOAuth,
		facebookOAuth,
		setState,
		state,
	};
}
