import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import useUser from '../../hooks/useUsers';
import NfButton from './ButtonLoading';
import Notificacion from './Notificacion';
import RegistroConPlataformas from './RegistroConPlataformas';
import NUBEFA_REGISTRO from '../../images/NUBEFA_REGISTRO.svg'
const RegisterForm = () => {

    const [Formulario, setFormulario] = useState({});

    const { register, isLoginLoading, hasResponse, hasResponseState } = useUser();

    const handleFormulario = (e) => {
        setFormulario({
            ...Formulario,
            [e.target.name]: e.target.value
        });
    };

    const Register = (e) => {
        e.preventDefault();
        console.log(register(Formulario));
    };

    return (
        <section className="container mt-5 pt-5">
			<div className="columns">
				<div className="column is-8 is-offset-2">
					<div className="columns">
						<div className="column mr-3 ml-3 mt-5">

							<p className="is-size-3 is-size-3-mobile has-text-weight-bold">Empieza hoy</p>
							<p className="is-size-4 is-size-5-mobile">Tu prueba gratis de 30 días</p>
							<p className="is-size-3 is-size-5-mobile">Regístrate para ver todas las bondades de Nubefa</p>
							
							{hasResponse.created != null && (
								<Notificacion
									is="is-link is-danger"
									CloseNotification={hasResponseState}
									Response={hasResponse}
								/>
							)}
							
							<img src={NUBEFA_REGISTRO} className="image" alt=""/>

						</div>
						<div className="column mr-3 ml-3 mt-3 has-text-centered mt-5">
							<div className="card">
								<div className="card-content">
									<h1 className="is-size-5 is-size-5-mobile has-text-weight-bold has-text-black mb-5">Registrate</h1>
									
									{hasResponse.created == null && <RegistroConPlataformas />}

									{hasResponse.created && <Redirect push to="/cliente/welcome/bienvenido" />}

									<div className="is-hidden-mobile mt-5" />
									<h1 className="is-size-5 is-size-5-mobile has-text-weight-bold has-text-black">Completa el Formulario</h1>

									<form>
										<div className="field">
											<div className="control">
												<input
													name="name"
													onChange={handleFormulario}
													className="input"
													type="text"
													placeholder="Nombres"
												/>
											</div>
										</div>

										<div className="field">
											<div className="control">
												<input
													name="lastname"
													onChange={handleFormulario}
													className="input"
													type="text"
													placeholder="Apellidos"
												/>
											</div>
										</div>

										<div className="field">
											<div className="control">
												<input
													name="email"
													onChange={handleFormulario}
													className="input"
													type="email"
													placeholder="Email"
												/>
											</div>
										</div>

										<div className="field">
											<div className="control">
												<input
													name="password"
													onChange={handleFormulario}
													className="input"
													type="password"
													placeholder="Contraseña"
												/>
											</div>
										</div>

										<div className="field">
											<div className="control">
												<input
													name="password_confirmation"
													onChange={handleFormulario}
													className="input"
													type="password"
													placeholder="Repita su Contraseña"
												/>
											</div>
										</div>

										<div className="field">
											<div className="control">
												<span className="select  is-fullwidth">
													<select onChange={handleFormulario} name="id_categoria">
														<option value="null">
															Seleccione una Categoría de usuario
														</option>
														<option value={1}>Empresario</option>
														<option value={3}>Usuario</option>
														<option value={1}>Contador</option>
														<option value={3}>Asistente de Contador</option>
													</select>
												</span>
											</div>
										</div>

										<div className="field">
											<div className="control">
												<span className="is-fullwidth">
													<NfButton
														is="is-block is-primary is-fullwidth"
														state={isLoginLoading}
														handleClick={Register}
														label="Regístrame"
													/>
												</span>
											</div>
										</div>

										<p>
											Al regístrate estas aceptando nuestros &nbsp;
											<Link to="/terminos-y-condiciones" className="">
												terminos y condiciones
											</Link>
										</p>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
};
export default RegisterForm;