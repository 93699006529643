require('dotenv');

export default function register(User) {
	const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/register' : process.env.REACT_APP_API_DEVELOP + '/register';
	return fetch(path, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(User)
	})
		.then((res) => {
			return res.json();
		})
		.then((res) => {
			return res;
		});
}
