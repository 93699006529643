import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import WhatsAppWidget from 'react-whatsapp-widget';
import phone from '../images/phone3.png';

const fecha = new Date().getFullYear();

const padding = {
	marginTop:'3%',
}

const Footer = () => (


    <footer className="footer" style={padding}>

				<div class="fab-left">
				<div className="phone">
					<a  href="tel:51993400767">
					<img src={phone}/>

						</a>
				</div>
					</div>
				<div className="is-fab">

 						<div className="whatsapp">
		 					<WhatsAppWidget
							companyName="Nubefa"
							phoneNumber="51993400767"
							textReplyTime="Activo hace 5 min"
							message="Hola! 👋🏼 En que podemos ayudarte?"
							sendButton="Enviar"
							/>
 						</div>
 						<div className="facebook">
 						</div>
 				</div>
			
						<MessengerCustomerChat pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID} appId={process.env.REACT_APP_FACEBOOK}/>

	<div className="container">
			<div className="columns is-mobile">
				<div className="column">
					<div className="columns colmd is-mobile">
						<div className="column is-half-mobile has-text-white is-size-7-mobile">
							<a href="tel:013256468" className="has-text-white">
								<div className="icon-text">
								  <span className="icon has-text-white">
								    <i className="fas fa-phone-rotary"></i>
								  </span>
								  <span>3256468</span>
								</div>
							</a>
							<a href="tel:51993400767" className="has-text-white">
								<div className="icon-text">
								  <span className="icon has-text-white">
								    <i className="fas fa-mobile-android-alt"></i>
								  </span>
								  <span>993400767</span>
								</div>
							</a>
							<a href="tel:51916661928" className="has-text-white">
								<div className="icon-text">
								  <span className="icon has-text-white">
								    <i className="fas fa-mobile-android-alt"></i>
								  </span>
								  <span>916661928</span>
								</div>
							</a>
						

				 		</div>
				 	 	<div className="column is-half-mobile has-text-white is-size-7-mobile">
				 	 		<a href="mailto:ventas@nubefa.com" className="has-text-white">
				 	 			<div className="">
								  <span className="icon has-text-white pr-1"  style={{display:'inline'}}>
								    <i className="fas fa-envelope"></i>
								  </span>
								  <span  style={{display:'inline'}}>ventas@nubefa.com</span>
								</div>
				 	 		</a>
				 	 	
							<a href="mailto:soporte@nubefa.com" className="has-text-white">
								<div >
								  <span className="icon has-text-white pr-1" style={{display:'inline'}}>
								    <i className="fas fa-envelope"></i>
								  </span>
								  <span style={
									  {display:'inline'}
								  }>soporte@nubefa.com</span>
								</div>

							</a>
					
							<div className="">
								  <span className="icon has-text-white pr-1" style={{display:'inline'}}>
								    <i className="fas fa-map-marker-alt	"></i>
								  </span>
								  <span style={{display:'inline'}} >Av. Betancourt 1097 Los Olivos</span>
								</div>
				 		</div>

					 </div>
				 </div>


			 </div>
			 <hr class="hr-footer"/>
			   <div className="content has-text-centered">
			    <p className="has-text-white">
			      Nubefa-Copyright &copy; 2022
			    </p>
			  </div>

	 </div>





	</footer>
);

export default Footer;