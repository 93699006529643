import React from 'react';

const NfButton = (props) => {
	//Label: text show
	//is : is-primary,is-danger,is-light
	let state = props.state === true ? 'is-loading' : '';
	let classNamed = `button ${state} ${props.is}`;

	return (
		<button onClick={props.handleClick} className={classNamed} disabled={props.state}>
			{props.label}
		</button>
	);
};
export default NfButton;
