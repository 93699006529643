import React, { useState } from 'react';

const Context = React.createContext({});

export function UserContextProvider({ children }) {
	const [ jwt, setjwt ] = useState(() => (sessionStorage.getItem('jwt') ? true : false));
	const [ plan,setPlan] = useState(false)
	
	return <Context.Provider value={{ jwt, setjwt,plan,setPlan }}>{children}</Context.Provider>;
}

export default Context;
