import React from "react";

import PlanActualCaracteristicas from "./dashboard/planactualcaracteristicas";
import PlanActualEstadisticas from "./dashboard/planactualestadisticas";
//import MethodoPago from "./dashboard/metodopago"
import InformacionFactuacion from "./dashboard/informacionfacturacion";
import HistorialPagos from "./dashboard/historialpagos";
const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="mr-3 ml-3">
      <PlanActualCaracteristicas></PlanActualCaracteristicas>
      <PlanActualEstadisticas></PlanActualEstadisticas>
      <InformacionFactuacion></InformacionFactuacion>
      <HistorialPagos></HistorialPagos>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
