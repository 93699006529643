import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Menu from './Menu';
import Header from '../Header';
import useUser from '../../hooks/useUsers';
import Clientes from '../../pages/Clientes';
const  PanelLayout= ({ children }) => {
	const { isLogged } = useUser();

	return (
		<React.Fragment>
		<Helmet>
			<title>NUBEFA | Cliente</title>
		</Helmet>
			{isLogged ? (
				<div>
					<Header/>
					<div className="container pt-5 mt-5">
						<div className="columns pt-5">
							<Menu className="" />
							<div className="column is-fullheight">{children}</div>
						</div>
					</div>
				</div>
			) : (
				<Clientes />
			)}
		</React.Fragment>
	);
};

const PanelLayoutRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<PanelLayout>
					<Component {...props} />
				</PanelLayout>
			)}
		/>
	);
};

export default PanelLayoutRoute;
