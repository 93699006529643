require('dotenv');

export default function googleOAuth(Response) {
	const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/OAuth' : process.env.REACT_APP_API_DEVELOP + '/OAuth';
	return fetch(path, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(Response)
	})
		.then((res) => {
			if (!res.ok) throw new Error('Response is not ok');
			return res.json();
		})
		.then((res) => {
			const jwt = res.access_token;
			return jwt;
		});
}
