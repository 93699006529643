import React from "react";
import { Link } from "react-router-dom";
import About from "../components/About";
import Header from "../components/Header";
import Modulos from "../components/Modulos";
import Pricings from "../components/Pricings";
import Stadistic from "../components/Stadistic";
import nubefa_nube_negra_1 from "../images/nubefa-black.svg";
import girl from "../images/nubefa-girl.svg";


const Inicio = () => {
  console.log();
  const style={
    "marginTop":"0.3em"
  }
    return (
        <div>
      <Header />
      <section className="pt-5">
        <div className="container pt-5 pl-3 mt-3">
          <div  style={{marginTop:"-0.3em"}} className="mb-small is-vcentered columns is-mobile" style={style}>
            <div className="column is-6-mobile correccion" style={{marginTop:"-0.8em"}}>
              <h2 className="is-size-patch-1 is-size-1 is-size-5-mobile has-text-weight-bold has-text-black  is-size-patch-mobile-1">
              <p >Software ERP</p>
              <p style={{marginTop:"-0.3em"}}>La Telegestión</p>
               <p style={{marginTop:"-0.3em"}}>de MYPEs</p>
              </h2>
              <p className="is-size-4 pt-4 is-size-patch-7 has-text-link is-size-7-mobile">
                ¡Pruebalo por 30 dias! 
              </p>

              <div className="field is-grouped">
               {/* <p className="control">
                  <button className="button is-primary">
                    <strong>Conoce más</strong>
                  </button>
                </p>*/}
                <p className="control">
                  <Link to="/registro" style={{marginTop:"1em"} }className="button is-link button-is-size-patch-1">
                    <strong>Regístrate</strong>
                  </Link>
                  <br />
                </p>
              </div>
            </div>

            <div className="column is-6-desktop is-6-mobile">
              <img
                className="nube_black_1 nube"
                src={nubefa_nube_negra_1}
                alt="NUBEFA_NUBE"
              />
              <img
                className="nube_black_2"
                src={nubefa_nube_negra_1}
                alt="NUBEFA_NUBE_2"
              />
              <img className="image is-fullwidth" src={girl} alt="NUBEFA" />
            </div>
          </div>
        </div>
      </section>

      <About />
      {/*<Evolve />*/}
      <Modulos />
      <Pricings />
      <Stadistic />
    </div>
    );
};

export default Inicio;