import React, { useContext,useEffect, useState } from "react";
import { Link,Redirect } from "react-router-dom";
import axios from "axios";

import Context from '../../../context/UserContext';

require("dotenv");

const PlanActualCaracteristicas = () => {
  const [Respuesta, setRespuesta] = useState(false);
  const [Caracteristicas, setCaracteristicas] = useState({});
  const { plan, setplan } = useContext(Context);
  useEffect(() => {
    if (!Respuesta){
      getCaracteristicas();
    }
  });

  const getCaracteristicas = (e) => {
    const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/plan-actual-caracteristicas' : process.env.REACT_APP_API_DEVELOP + '/plan-actual-caracteristicas';

    const res = axios.post(path, {}, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
      }
    })
    res.then(
      (response) => {
        setCaracteristicas(response.data[0]);
        setRespuesta(true);
      },
      (error) => {
        setRespuesta(false);
      }
    );
  };

  return (
    <React.Fragment>
      {Caracteristicas.id === 4 && !plan  ? <Redirect push to="/cliente/pagos" />:''}
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            Plan Actual
          </p>
          <a className="card-header-icon" aria-label="more options">
            <span className="icon">
              <i className="fal fa-info-circle" aria-hidden="true"></i>
            </span>
          </a>
        </header>
        <div className="card-content">
          <div className="content is-size-7-mobile is-size-7">
            <h1 className="is-size-4">{Caracteristicas.description}</h1>
              <table>
                <tbody>
                  <tr>
                    <td>Comprobantes</td>
                    <td><i className="fal fa-infinity" /> </td>
                  </tr>
                  <tr>
                    <td>Empresas</td>
                    <td>{Caracteristicas.limi_empresa}</td>
                  </tr>
                  <tr>
                    <td>Locales</td>
                    <td>{Caracteristicas.limi_local}</td>
                  </tr>
                  <tr>
                    <td>Usuarios</td>
                    <td>{Caracteristicas.limi_user}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
        <footer className="card-footer">
           <Link className="card-footer-item" to="/cliente/pagos">
                  <strong>Cambiar de Plan</strong>
            </Link>
          <span className="card-footer-item has-text-right">  <small>s/</small> &nbsp;<span className="is-size-2 has-text-weight-bold has-text-secundary">{Caracteristicas.precio}</span></span>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default PlanActualCaracteristicas;
