import React from 'react';

const About = () => {

return	(
	<section className="pt-0">		
		<h1 className="is-size-1 mt-5 is-size-5-mobile has-text-weight-bold has-text-centered is-size-patch-1">Facturas Electrónicas ilimitadas</h1>
		<div className="container pt-1">
				<div className="columns is-mobile is-centered">
					<div className="column">		
						<div className="has-text-centered is-size-4 is-size-6-mobile pl-3">
							<div className="mt-1">
							Gestiona todo desde un solo sitio
							</div>
						</div>
					</div>		
			</div>
		</div>
		<h1 className="is-size-1 mt-1 is-size-5-mobile has-text-weight-bold has-text-centered is-size-patch-1">Multiempresa y Multilocal</h1>

	</section>
	)

}
export default About;