import React,{useEffect,useState} from 'react'
import axios from 'axios'

const PaymentMethods = () => {

const [Bancos, setBancos] = useState([]);

const [Respuesta, setRespuesta] = useState(false);

    useEffect(() => {
        if(!Respuesta){
            getBanks(); 
           }
    });


const getBanks = () => {
        const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/banks-accounts-nubefa' : process.env.REACT_APP_API_DEVELOP + '/banks-accounts-nubefa';

        const res = axios.post(path, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
            }
        })
        res.then(
            (response) => {
                setRespuesta(true)
                setBancos(response.data);
            },
            (error) => {
                setRespuesta(false)
                console.log('error')
            }
        );
}



    return (
        <React.Fragment>
            <section className="container">
                <h4 className="title">
                    <i className="fal fa-university" /> Cuentas Bancarias
                </h4>
                    <div className="columns">
                    {Bancos.map((banco) => {
                        return (
                            <div className="column">
                                <div className="box">
                                    <h1 className="is-size-6">{banco.description}</h1>
                                    <p>{banco.propietary}</p>
                                    <p>{banco.number_account}</p>
                                </div>
                            </div>
                        );
                        })}
                    </div>
            </section>
        </React.Fragment>
    );
};
export default PaymentMethods;