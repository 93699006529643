import React,{useState} from 'react';
import axios from 'axios';

const Forget = ({setShowForget}) => {

	const [state,setState] = useState('');

	const handleFormulario = (e) => {
		setState(e.target.value);
	};

	const sendEmailPassword = (e)=>{
		e.preventDefault();
        const rute = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/forgetPassword' : process.env.REACT_APP_API_DEVELOP + '/forgetPassword';
		const res = axios.post(rute,{email:state});
		res.then(
			(response) => {
				console.log('fine');
				alert('Correo enviado');
				setState('');
			},
			(error) => {
				console.log(error);
			}
		);
		setState('');
	}

	const close = (e)=> {
		e.preventDefault();
		setShowForget(false);
	}

return(
	<form>
			<div className="title">Olvidaste tu contraseña?</div>
			<div className="field">
				<div className="control">
					<input
						onChange={handleFormulario}
						className="input"
						type="email"
						placeholder="Email de Recuperación"
						value={state}
						name="username"
					/>
				</div>
			</div>

			<div className="field">
				<div className="control">
					<button className="is-block is-primary is-fullwidth button" onClick={sendEmailPassword}>
						Enviar Correo de Recuperación
					</button>
					<br/>

					<button className="is-block is-danger is-outlined is-fullwidth button" onClick={close}>
						Cancelar
					</button>
				</div>
			</div>
		</form>

	)

}

export default Forget;