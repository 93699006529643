import React, { useEffect, useState } from "react";
import axios from "axios";

require("dotenv");

const PlanActualEstadisticas = () => {
  const [Respuesta, setRespuesta] = useState(false);
  const [Estadisticas, setEstadisticas] = useState({});
  useEffect(() => {
    if(!Respuesta){
      getEstadisticas();
    }
  });

  const getEstadisticas = (e) => {
    const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/plan-actual-estadisticas' : process.env.REACT_APP_API_DEVELOP + '/plan-actual-estadisticas';

    const res = axios.post(path, {}, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
      }
    })
    res.then(
      (response) => {
        setEstadisticas(response.data.success);
        setRespuesta(true)

      },
      (error) => {
        setRespuesta(false);
      }
    );
  };

  return (
    <React.Fragment>
       <div className="card mt-5">
        <header className="card-header">
          <p className="card-header-title">
           Uso del Plan Actual
          </p>
          <a className="card-header-icon" aria-label="more options">
            <span className="icon">
              <i className="fal fa-info-circle" aria-hidden="true"></i>
            </span>
          </a>
        </header>
        <div className="card-content">
          <div className="content is-size-7-mobile is-size-7">
            <h1 className="is-size-4"></h1>
              <table>
                <tbody>
                  <tr>
                    <td>Empresas</td>
                    <td>{Estadisticas.empresas}</td>
                  </tr>
                  <tr>
                    <td>Locales</td>
                    <td>{Estadisticas.locals}</td>
                  </tr>
                  <tr>
                    <td>Usuarios</td>
                    <td>{Estadisticas.usuarios}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlanActualEstadisticas;
