import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Context from '../context/UserContext';
import useUser from '../hooks/useUsers';
const Pricings = () => {
    const [title, setTitle] = useState('');
    const [fontSize, setfontSize] = useState('1');
    const [Active, setActive] = useState(1)
    const { plan, setPlan } = useContext(Context);
    const { isLogged } = useUser();
    const history = useHistory()

    useEffect(() => {
        setRender();
    });

    const setRender = () => {
        if (history.location.pathname === '/cliente/pagos') {
            setTitle('Aumenta el Plan que Posees')
            setfontSize('3');
        } else if (history.location.pathname === '/comprobante') {
            setTitle('Conoce y empieza a usar Nubefa')
            setfontSize('3');
        } else {
            setTitle('Elige tu plan');
            setfontSize('1');
        }
    };

    const handleFree = (e) => {
        e.preventDefault();
        isLogged && !plan ?
            handleFreePlan() :
            history.push("/cliente")
    }

    const handleFreePlan = () => {
        setPlan(true);
        history.push("/cliente")
    }

    return (
        <div className="">
			<div className="container">
				<h1 className={'is-size-'+fontSize+' is-size-patch-1 is-size-3-mobile has-text-weight-bold  has-text-centered pl-3 pr-3'}>{title}</h1>
				<div className="pricing-table pt-2 mt-2 pl-5 pr-5">
				    <div className={(Active === 0)? 'pricing-plan pricing-plan-activable mt-5 mb-0 is-active ml-1 border box':'pricing-plan box mt-5 border ml-1 mb-5'}
				   onMouseEnter={() => setActive(0)}
				  >
				    <div className="plan-price"><span className="plan-price-amount mr-1 has-text-weight-light"><span className="plan-price-custom">S/</span>0</span>x mes</div>
				    <div className="plan-header"><h2 className="is-size-3 has-text-weight-bold">Gratis</h2>
				    <p className="plan-description is-size-7 has-text-weight-light">30 días libre.</p></div>
				    <div className="plan-items">
					<div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Local</div>

				    <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; Todos los Módulos</div>
				    <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Empresa</div>
				    <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Usuario</div>
				    </div>
				    <div className="plan-footer">
				    	<button onClick={handleFree} className="button is-link">
							{isLogged && !plan
						        ? 'Continuar Gratis ...' 
						        : 'Seleccionar' 
						    }										    		
						</button>
				    </div>
				  </div>

				   <div className={(Active === 1)? 'pricing-plan pricing-plan-activable mt-5 mb-5 ml-1 is-active border box':'pricing-plan border box ml-1 mt-5 mb-5'}
				   onMouseEnter={() => setActive(1)}
				  >
				    <div className="plan-price is-flex is-justify-content-center is-align-items-end">
						<span className="plan-price-amount mr-1 has-text-weight-light">
							<span className="plan-price-custom">
								S/
							</span>
						39
						</span>
						<span className="mb-5">x mes</span>
						<span className="is-align-self-flex-start"><span className="icon">
							<i className="fas fa-plus is-green"></i></span>IGV</span>
					</div>
				    <div className="plan-header"><h2 className="is-size-3 has-text-weight-bold">Local</h2>
				    	<p className="is-size-7 has-text-weight-light">Ideal para empresas con 1 local </p>
				    </div>
				    <div className="plan-items">
					<div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Local</div>
    			     <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; Todos los Módulos</div>
	  			     <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; Facturas Ilimitadas</div>
				      <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Empresa</div>
				      <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 2 Usuarios</div>
				    </div>
				    <div className="plan-footer">
				    	<Link to="/cliente/payment/basic/upgrade" className="button is-link">
							Seleccionar
						</Link>
				    </div>
				  </div>

				  <div className={(Active === 2)? 'pricing-plan pricing-plan-activable mt-5 mb-5 ml-1 border is-active box':'pricing-plan border box ml-1 mt-5 mb-5'}
				   onMouseEnter={() => setActive(2)}
				  >


<div className="plan-price is-flex is-justify-content-center is-align-items-end">
						<span className="plan-price-amount mr-1 has-text-weight-light">
							<span className="plan-price-custom">
								S/
							</span>
						69
						</span>
						<span className="mb-5">x mes</span>
						<span className="is-align-self-flex-start"><span className="icon">
							<i className="fas fa-plus is-green"></i></span>IGV</span>
					</div>
				    <div className="plan-header"><h2 className="is-size-3 has-text-weight-bold">Duo Local</h2>
				    	<p className="is-size-7 has-text-weight-light">Ideal para empresas con 2 locales</p>
						</div>
				    <div className="plan-items">
					<div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 2 Locales</div>
				    <div className="plan-item">

						<i className="fad fa-check-circle"></i> &nbsp; Todos los Módulos</div>
				       <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; Facturas Ilimitadas</div>
				      <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Empresa</div>
				      <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 4 Usuarios</div>
				    </div>
				    <div className="plan-footer">
				    	<Link to="/cliente/payment/standar/upgrade" className="button is-link">
							Seleccionar
						</Link>
				    </div>
				  </div>
				  <div className={(Active === 3)? 'pricing-plan pricing-plan-activable mt-5 mb-5 ml-1 border is-active box':'pricing-plan border box ml-1 mt-5 mb-5'}
				   onMouseEnter={() => setActive(3)}
				  >
				    <div className="plan-price is-flex is-justify-content-center is-align-items-end">
						<span className="plan-price-amount mr-1 has-text-weight-light">
							<span className="plan-price-custom">
								S/
							</span>
						99
						</span>
						<span className="mb-5">x mes</span>
						<span className="is-align-self-flex-start"><span className="icon">
							<i className="fas fa-plus is-green"></i></span>IGV</span>
					</div>
				    <div className="plan-header"><h2 className="is-size-3 has-text-weight-bold">Tri Local</h2>
				    	<p className="is-size-7 has-text-weight-light">Ideal para empresas con 3 locales</p>
				    </div>
				    <div className="plan-items">
					<div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 3 Locales</div>

				    <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; Todos los Módulos</div>
				     <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; Facturas Ilimitadas</div>
				      <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp; 1 Empresa</div>
				      <div className="plan-item"><i className="fad fa-check-circle"></i> &nbsp;  6 Usuarios</div>
				    </div>
				    <div className="plan-footer">
				    	<Link to="/cliente/payment/premium/upgrade" className="button is-link">
							Seleccionar
						</Link>
				    </div>
				  </div>
				</div>
				</div>
		</div>
    );
};

export default Pricings;