import React from 'react';

const Notificacion = ({ is, CloseNotification, Response }) => {
	const Close = (e) => {
		e.preventDefault();
		CloseNotification({ created: null, errors: null, success: null });
	};

	return (
		<div className={is + ' notification'}>
			<button className="delete" onClick={Close} />
			<ul>
				{Response.errors != null &&
					Response.errors.map((v, k) => {
						return <li key={k}>{v}</li>;
					})}
			</ul>
		</div>
	);
};

export default Notificacion;
