import React from 'react';
import girl_shop_nubefa from '../images/nubefa_girl_33.png';

const Stadistic = () => {

    const divOne = {
        height: "100px",
        color: 'black',
    }
    const divTwo = {
        height: "100px",
        color: 'black',
    }

    const divThree = {
        height: "100px",
        color: 'black',
    }
    const title = {
    	marginTop: "6%",
    }
    return (
<>
		<div className="container pt-3 mt-5 mb-5">
			<h1 className="is-size-1 is-size-patch-1 is-size-5-mobile has-text-weight-bold has-text-centered pl-3 pr-3">Nuestras Estadisticas</h1>
		</div>
		<div className="container mt-3">
				<div className="columns is-mobile margin-b">
					<div className="column">
						<div className="columns is-mobile">
							<img src={girl_shop_nubefa} alt="nubefa_girl_3" className="nubefa_girl_3 imagen-chica"/>
						</div>
					</div>	
					<div className="column estadisticas">
						<div className="has-text-left is-size-3 is-size-7-mobile ">
						<span className="item-stat" style={divOne}>+700 Empresas Activas</span><br/>
						<span className="item-stat" className="has-text-weight-bold" style={divTwo}>+140,000 Facturas por Mes </span><br/>
						<span className="item-stat" style={divThree}>+1500 Usuarios Activos </span><br/>
					</div>
				</div>
			</div>
		</div>
		</>


    )

}

export default Stadistic;