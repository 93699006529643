import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const PagoNiubiz = (props) => {
	const { plan, action } = useParams();
	const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/pagar-servicio/' + sessionStorage.getItem('jwt') + '/' + plan + '/' + action : process.env.REACT_APP_API_DEVELOP + '/pagar-servicio/' + sessionStorage.getItem('jwt') + '/' + plan + '/' + action;
	return (
		<React.Fragment>
			<div className="field is-grouped">
				<p className="control is-expanded">
					<label className="checkbox">
						<input type="checkbox" />
						&nbsp; Acepto los &nbsp;
						<Link to="/terminos-y-condiciones">
							Terminos y Condiciones
					</Link>
					</label>
				</p>
				<p className="control">
					<a href={path} className="button is-primary is-fullwidth">Proceder a Pagar</a>
				</p>
			</div>
		</React.Fragment>
	);
};

export default PagoNiubiz;
