import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NfButton from '../elements/ButtonLoading';
import RegistroConPlataformas from '../../components/elements/RegistroConPlataformas';


const LoginForm = ({ handle, target, state, load, error, setShowForget}) => {
	const handleFormulario = (e) => {
		state({
			...target,
			[e.target.name]: e.target.value
		});
	};

	const closeError = (e) => {
		e.preventDefault();
		error = false;
	};
	const forgetOpen = (e) => {
		e.preventDefault();
		setShowForget(true)
	}

	return (
		<form>
			<div className="is-size-3 is-size-5-mobile has-text-weight-bold has-text-black">Inicia Sesión</div>
			<div className="field">
				<div className="control">
					<input
						onChange={handleFormulario}
						className="input"
						type="text"
						placeholder="Nombres"
						name="username"
					/>
				</div>
			</div>

			<div className="field">
				<div className="control">
					<input
						name="password"
						onChange={handleFormulario}
						className="input"
						type="password"
						placeholder="Contraseña"
					/>
				</div>
			</div>

			<div className="field">
				<div className="control">
					<NfButton
						is="is-block is-primary is-fullwidth"
						label="Ingresar"
						handleClick={handle}
						state={load}
					/>
				</div>
			</div>

			{error && (
				<div className="notification is-danger">
					<button className="delete" onClick={closeError} />
					Credenciales Incorrectas
				</div>
			)}

			<RegistroConPlataformas />
			<button className="button is-block is-ghost is-fullwidth" onClick={forgetOpen}>
				¿Olvidaste tu Contraseña?
			</button>
			<Link to="/registro" className="button is-block is-ghost is-fullwidth">
				¿Aun no tienes tu Cuenta?
			</Link>
		</form>
	);
};
export default LoginForm;
