import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

require("dotenv");

const HistorialPagos = () => {

    const [Respuesta, setRespuesta] = useState(false)
    const [Historials, setHistorials] = useState([])

    useEffect(() => {
        if (!Respuesta) {
            getHistorial();
        }
    });

    const getHistorial = (e) => {
        const path = (process.env.NODE_ENV ==='production') ? process.env.REACT_APP_API_URL + '/history-invoices' : process.env.REACT_APP_API_DEVELOP + '/history-invoices';
        const res = axios.post(path, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
            }
        })
        res.then(
            (response) => {
                setHistorials(response.data);
                setRespuesta(true);
            },
            (error) => {
                setRespuesta(false);
            }
        );
    }

    return (
    <React.Fragment>
        <div className="card mt-5">
            <header className="card-header">
              <p className="card-header-title">
                Historial de Pagos
              </p>
              <a className="card-header-icon" aria-label="more options">
                <span className="icon">
                  <i className="fal fa-clock" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div className="card-content">
              <div className="content is-size-7-mobile is-size-7">
                     <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th>Fecha Emision</th>
                                <th>Fecha Vencimiento</th>
                                <th>Monto</th>
                                <th>Documento</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Fecha Emision</th>
                                <th>Fecha Vencimiento</th>
                                <th>Monto</th>
                                <th>Documento</th>
                                <th>Estado</th>
                            </tr>
                        </tfoot>
                        <tbody>
                            {Historials.map((history) => {
                                return (
                                    <tr>
                                        <td>{history.created_at}</td>
                                        <td>{history.fecha_exp}</td>
                                        <td>{history.total}  {history.currency}</td>
                                        <td><a href={process.env.REACT_APP_API_URL + '/invoice/' + history.token}>FA-F002-{history.correlativo}</a></td>
                                        <td>
                                           {
                                                (history.status === 3 && <button className="button is-small is-danger">Rechazado</button>) ||
                                                (history.status === 2 && <button className="button is-small is-secundary">Verificando</button>) ||
                                                (history.status === 1 && <button className="button is-small is-primary">Pagado</button>) ||
                                                (history.status === null && <Link className="button is-small is-primary" to={'/cliente/payment/' + history.token + '/pay'}>Pagar</Link>)
                                            }
                                        </td>
                                    </tr>);
                            })}
                        </tbody>
                    </table>
              </div>
            </div>
        </div>
    </React.Fragment >
    )
}

export default HistorialPagos;